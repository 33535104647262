//material ui and services components import
import { 
    Accordion, 
    AccordionDetails, 
    AccordionSummary, 
    Backdrop, 
    Fade,
    FormControlLabel,
    FormControl,
    Button,
    Grid,
    IconButton,
    Modal,
    Select,
    Switch,
    TextField,
    makeStyles,
} from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Alert from '@mui/material/Alert';
import jsPDF from 'jspdf';
import * as _ from 'lodash';
import moment from "moment-timezone";
//external library imports
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from "react";
import ReactDOMServer from 'react-dom/server';
import { useLocation } from "react-router-dom";
import { sendActionToAdobeAnalytics } from '../../../../common/analytics/adobeanalytics/adobeanalytics';
//constants and services import
import { bargraph, defaultreportlayoutsetting, ENVIRONMENT, redcoloredsectionsnamearray, reporterrorarray, REPORT_TYPE, SNACKBAR_TYPE, dc35cullingreporttext, table_headers, row_subheader } from '../../../../common/constants/constants';
//components import
import Loader from '../../../../common/dialogs/loader/loader';
import TwoButtonDialog from "../../../../common/dialogs/two-button-dialog/two-button-dialog";
import { postSnapshotGraphsUrl } from '../../../../common/services/graphs.service';
import { 
    generateBenchmarkReport, 
    generateCullingHistoryReport, 
    generatePeriodComparisonReport, 
    generateSnapshotGoalReport, 
    generateSnapshotReport, 
    generateSnapsixReport, 
    generateSubherdComparisonReport,
    generateHeiferReport
} from '../../../../common/utils/Reporting/generateReport';
import { addReportLayout, deleteMyFavoriteReportLayout, getReportLayout, getReportResponse } from '../../../../common/utils/Reporting/Report.service';
import { addWatermark, customizationallowedreport, getcelldata, getchartimagebloburlandgenerateReport, new_getcondensedchartpagesetting, getPdfDynamicSettings, getdynamicsettings, getreporttext, getrowheadercontent, getsectionname, reportsetting, reporttotaltestdays, setchartgraph } from '../../../../common/utils/Reporting/reporthelper';
import { gettestdates } from "../../../../common/utils/Reporting/testdates.service";
import { getEnvironment, decodeApiResponse } from '../../../../common/utils/utility';
import Dropdown from '../../../ui_utilities/Dropdown';
import ModalFlyout from '../../../ui_utilities/modalFlyout';
import Textbox from '../../../ui_utilities/Textbox';
import ChartAndGraphs from "./chartandgraph/ChartAndGraphs";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 122,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        width: '500px',
        height: '300px',
        border: '2px solid transparent',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    }
}));

const Reporting = (props) => {
    const { enqueueSnackbar } = useSnackbar();
    const classes = useStyles();
    const [lactationcurvemaxy, setlactationcurvemaxy] = useState(null)
    const [lactationcurveminy, setlactationcurveminy] = useState(null)
    const [loaderstatus, setloaderstatus] = useState(false);

    const [reporttab, setreporttab] = useState("");
    const [reporttesdays, setreporttestdays] = useState([]);
    const [selectedtestdates, setselectedtestdates] = useState([]);
    const [emptyDate, setEmptyDate] = useState(false);
    const [showtestday, setshowtestday] = useState(false);
    const [chartexpanded, setchartexpanded] = useState(true);

    const [reportgenerated, setreportgenerated] = useState(false);
    const [showiframe, setshowiframe] = useState(false);
    const [displayChart, setDisplayChart] = useState(false);
    const bottomRef = React.useRef();
    const [toggleEnable, setToggleEnable] = useState(false);
    const [htmlreport, sethtmlreport] = useState("");
    const [heiferCulling_htmlreport, setHeiferCulling_htmlreport] = useState("");
    const [reportsettings, setreportsettings] = useState(null);
    const [disableCustomReport, setDisableCustomReport] = useState(true);

    const [graphdata, setgraphdata] = useState([]);
    const [reportResponse, setReportResponse] = useState(false);

    const [graphdataresponse, setgraphdataresponse] = useState(null);
    const [reportdataresponse, setreportdataresponse] = useState(null);

    const [validError, setValidError] = useState(false);
    const [showReportError, setshowReportError] = useState(false);
    const [ReportError, setReportError] = useState(null);

    const [reportlayoutsetting, setreportlayoutsetting] = useState({});
    const [customreportlayout, setcustomreportlayout] = useState(false);
    const [selectedcustomreportlayout, setselectedcustomreportlayout] = useState("");
    const [selectedmyfavouritereportsetting, setselectedmyfavouritereportsetting] = useState("");

    const [customreportlayoutoptions, setcustomreportlayoutoptions] = useState([]);
    const [myfavouritereportlist, setmyfavouritereportlist] = useState([]);
    const [subherdreportlayoutsetting, setsubherdreportlayoutsetting] = useState([]);
    const [graphimgurlarraydata, setgraphimgurlarraydata] = useState([]);

    const [myfavouritelistcounter, setmyfavouritelistcounter] = useState(0);
    const [subherdreportlayoutcounter, setsubherdreportlayoutcounter] = useState(0);
    const [showsavesubherdreportlayoutconfirmbox, setshowsavesubherdreportlayoutconfirmbox] = useState(false);
    const [showmyfavoritereportupdateconfirmbox, setshowmyfavoritereportupdateconfirmbox] = useState(false);
    const [showmyfavoritedeleteconfirmbox, setshowmyfavoritedeleteconfirmbox] = useState(false);

    const [reportgenerationdata, setreportgenerationdata] = useState(null);

    const [callreportcounter, setcallreportcounter] = useState(0);
    const [generatereportcounter, setgeneratereportcounter] = useState(0);

    const location = useLocation();
    const [open, setOpen] = useState(false);
    const [managecustomReportOpen, setmanagecustomReportOpen] = useState(false);
    const [customreportname, setcustomreportname] = useState("");
    const [customreportnameoverwrite, setcustomreportnameoverwrite] = useState("");
    const [tobeeditedlayoutid, settobeeditedlayoutid] = useState(null);
    const [reporterrorindex, setreporterrorindex] = useState(-1);
    const [uploaddatadetails, setuploaddatadetails] = useState([]);

    const [customGraphData, setCustomGraphData] = useState([]);
    const [customLayout, setCustomLayout] = useState(false);
    const [customLayloutWithChanges, setCustomLayoutWithChanges] = useState(false);

    const useavgdailymilkflag = props.subherddata.use_avg_daily_milk ? props.subherddata.use_avg_daily_milk : false;

    const initialGraphTemplate = [
        { key: "1", id: "ecmchart", name: "Energy Corrected Milk", value: true, width: "half_page" },
        { key: "2", id: useavgdailymilkflag ? "weeklymilkchart" : "testdaymilkchart", name: "Test Day Milk", value: true, width: "half_page" },
        { key: "3", id: "lactation1chart", name: "1st Lactation", value: true, width: "half_page" },
        { key: "4", id: "lactation2chart", name: "2nd Lactation", value: true, width: "half_page" },
        { key: "5", id: "lactation3chart", name: "3rd+ Lactation", value: true, width: "half_page" },
        { key: "6", id: "herddatachart", name: "Percent Pregnant in Herd", value: true, width: "half_page" },
        { key: "7", id: "calfheiferchart", name: "1st Calf Heifer Profiles", value: true, width: "half_page" },
        { key: "8", id: "daysopenchart", name: "Days Open", value: true, width: "half_page" },
        { key: "9", id: "percentfatchart", name: "Percent Fat", value: true, width: "half_page" },
        { key: "10", id: "somaticcellchart", name: "Somatic Cell", value: true, width: "half_page" },
        { key: "11", id: "percentproteinchart", name: "Percent Protein", value: true, width: "half_page" },
        { key: "12", id: "totallbschart", name: "Total lbs fat+protein", value: true, width: "half_page" },
        { key: "13", id: "avgdimchart", name: "Average DIM", value: true, width: "half_page" },
        { key: "14", id: "calvingintervalchart", name: "Calving Intervals", value: true, width: "half_page" },
        { key: "15", id: "annualcullchart", name: "Annual Cull", value: true, width: "half_page" },
        { key: "16", id: "lactationcurvechart", name: "Lactation Curve", 
            value: (["cullinghistory", "snapsix", "periodcomparison"].includes(reporttab)) ? false : true, 
            width: "half_page" 
        },
    ];
    const [graphTemplate, setGraphTemplate] = useState(initialGraphTemplate);

    useEffect(() => {
        setGraphTemplate(initialGraphTemplate)
        setDisableCustomReport(true)
        setCustomLayout(false);
    }, [reporttab])

    useEffect(() => {
        if (!customreportlayout && reporttab !== "snapshotgoals") {
            setGraphTemplate(initialGraphTemplate)
        }
    }, [customreportlayout])
    
    useEffect(() => {
        if(selectedcustomreportlayout !== ""  && selectedcustomreportlayout !== null) {
            setCustomLayout(true);
            let obj = myfavouritereportlist.find((d) => d.id === selectedcustomreportlayout);
            if(obj.chartlayoutsetting.length > 0) {
                setDisplayChart(true);
                handleReselectChart();
            } else {
                setDisplayChart(false);
            }
        }
    }, [selectedcustomreportlayout]);

    let report_date_selection_section = null, text_for_snapshot_goals_report = null, reportbutton = null, reportlabel = null;

    const gettestdates_rep = (data) => {
        let totaltestdates = data.totaldates
        let testdays = [];
        for (let i = 0; i < totaltestdates; i++) {
            if (data.name === "periodcomparison") {
                if (i % 2 === 0) {
                    testdays.push("Start Date");
                } else {
                    testdays.push("End Date");
                }
            } 
            else if (data.name === "subherdcomparison") {
                testdays.push("Test Date");
            }
            else {
                testdays.push(props.herddata.software_herd_software === "DC305" ? "Backup Date" : "Test Date");
            }
        }
        
        let datesection = testdays.map((testday, dateindex) => {

            let localreporttesdays = [...reporttesdays];
            let disableselect = false;

            let selectvalue = selectedtestdates.length === 0 ? "" : selectedtestdates[dateindex] === null ? "" : selectedtestdates[dateindex];
            if (data.name === "periodcomparison") {
                if (dateindex > 0) {
                    if (selectedtestdates[dateindex - 1] !== undefined) {
                        localreporttesdays = [...reporttesdays].reverse();
                        let sliceindex = localreporttesdays.indexOf(selectedtestdates[dateindex - 1]);
                        localreporttesdays = dateindex % 2 !== 0 ? localreporttesdays.slice(sliceindex).reverse() : localreporttesdays.slice(sliceindex + 1).reverse();

                    } else {
                        disableselect = true;
                        selectvalue = ""
                    }
                }
            }

            return (
                <div className="test-date-select-group" key={dateindex}>
                    {
                        data.name === "periodcomparison" ? <div>
                            {
                                dateindex % 2 === 0 ? <React.Fragment>
                                    <p style={{ paddingLeft: "45%", fontWeight: "bold" }}>Period {(dateindex / 2 + 1)}
                                    </p>
                                </React.Fragment> : null
                            }
                            <label className="form-label">{testday}</label>
                        </div> : <label className="form-label">{testday}</label>
                    }
                    <FormControl variant="outlined" className={classes.formControl}>

                        <Select
                            native
                            className="drop-down-root"
                            value={selectvalue}

                            disabled={emptyDate || disableselect}
                            onChange={(event) => {
                                let temp = [...selectedtestdates];
                                if (data.name === "periodcomparison") {
                                    if ((temp.length - 1) > dateindex) {
                                        if (event.target.value > temp[dateindex + 1]) {
                                            let revisedtemp = [];
                                            for (let i = 0; i < dateindex; i++) {
                                                revisedtemp.push(temp[i])
                                            }
                                            temp = revisedtemp;
                                        }
                                    }
                                    temp[dateindex] = event.target.value;
                                }
                                if (temp.length === 4) {
                                    reportgeneration({
                                        name: reporttab,
                                        label: ""
                                    }, "graphswitch", temp);
                                } else {
                                    setDisplayChart(false);
                                    temp[dateindex] = event.target.value;
                                    setselectedtestdates(temp);
                                    if (["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(data.name)) {
                                        const newChartData = graphTemplate.filter(item => item.id !== "lactationcurvechart");
                                        setgraphdata(newChartData)
                                    } else {
                                        setgraphdata(graphTemplate)
                                    }
                                    setreportdataresponse(null);
                                    setchartexpanded(true);
                                    setgraphdataresponse([]);
                                    setshowiframe(false);
                                    setreportgenerated(false);
                                    setgraphimgurlarraydata([]);
                                }
                            }}
                            inputProps={{
                                name: testday,
                                id: testday,
                            }}
                        >
                            <option aria-label="None" value="" />
                            {
                                localreporttesdays.map((name, index) => {
                                    return (
                                        <option key={index} value={name}
                                        >
                                            {name.substring(5, 7) + "/" + name.substring(8, 10) + "/" + name.substring(0, 4)}
                                        </option>
                                    )
                                })
                            }
                        </Select>
                    </FormControl>
                </div>
            )
        })
        
        return datesection;
    }

    const handleChangeChart = () => {
        if (!displayChart) {
            reportgeneration({
                name: reporttab,
                label: ""
            }, "graphswitch", null);
            setchartexpanded(true);
            setDisplayChart(!displayChart)
        } else {
            if (["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(reporttab)) {
                const newChartData = graphTemplate.filter(item => item.id !== "lactationcurvechart");
                setgraphdata(newChartData)
            } else {
                setgraphdata(graphTemplate)
            }
            setchartexpanded(true);
            setDisplayChart(!displayChart)
            setgraphimgurlarraydata([])
        }
        setshowiframe(false);
        setreportgenerated(false);
    };

    const handleReselectChart = () => {
        if (!displayChart) {
            reportgeneration({
                name: reporttab,
                label: ""
            }, "graphswitch", null);
        } else {
            if (["cullinghistory", "snapsix", "periodcomparison"].includes(reporttab)) {
                const newChartData = graphTemplate.filter(item => item.id !== "lactationcurvechart");
                setgraphdata(newChartData)
            } else {
                setgraphdata(graphTemplate)
            }
            setchartexpanded(true);
            setgraphimgurlarraydata([])
        }
    }

    const handleOpen = () => {
        setOpen(true);
        setselectedmyfavouritereportsetting("")
    };

    const handleClose = () => {
        setOpen(false);
        setcustomreportname("");
        setcustomreportnameoverwrite("");
        setreporterrorindex(-1);
        setselectedmyfavouritereportsetting("")
    };

    // ################################################################
    // React.useEffect START
    // ################################################################

    useEffect(() => {
        if (callreportcounter !== 0) {
            generatereport(reportgenerationdata);
        }
    }, [callreportcounter])
    

    useEffect(() => {
        getReportLayout("myfavorite", null).then((response) => {
            let tempcustomreportlayoutoption = decodeApiResponse(response.data);
            tempcustomreportlayoutoption = tempcustomreportlayoutoption.map((d) => {
                return {
                    name: d.name,
                    value: d.id
                }
            })
            setmyfavouritereportlist(response.data)
            setcustomreportlayoutoptions(tempcustomreportlayoutoption)
        }).catch((err) => {
            console.log(err);
        })
    }, [myfavouritelistcounter])

    useEffect(() => {
        setgraphdata(graphTemplate)
    }, [])

    useEffect(() => {
        let subherdid = props.subherddata?.sub_herd_id;
        if (subherdid) {
            getReportLayout("subherd", subherdid).then((response) => {
                const new_response = decodeApiResponse(response);
                if (response.data.length === 0) {
                    setreportlayoutsetting(_.cloneDeep(defaultreportlayoutsetting));
                    setsubherdreportlayoutsetting([]);
                } else {
                    setreportlayoutsetting(_.cloneDeep(new_response.data[0].reportlayoutsetting));
                    setsubherdreportlayoutsetting(_.cloneDeep(new_response.data));
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [subherdreportlayoutcounter])

    useEffect(() => {
        if (showiframe) {
            bottomRef.current.scrollIntoView({
                behavior: "smooth",
                block: "start",
            });
        }
    }, [showiframe, generatereportcounter])

    let selectedGraph = [];

    if (graphdata.length > 0)
        selectedGraph = [...graphdata]

    useEffect(() => {
        setloaderstatus(true);
    }, [])

    // Get the Test days for the subherd
    useEffect(() => {
        let subherddetaildata = props.subherddata;
        let subherdid = subherddetaildata?.sub_herd_id;
        gettestdates({
            sub_herd_id: subherdid,
            ...subherddetaildata,
            software_herd_software: props.herddata.software_herd_software
        }).then((data) => {
            setloaderstatus(false);
            if (data.response.length > 0) {
                setEmptyDate(false);
                let tempuploaddatadetails = JSON.parse(data.uploaddatadetails)
                let testdays = tempuploaddatadetails[0].testdates[0];
                setuploaddatadetails(tempuploaddatadetails)
                setreporttestdays(testdays);

                setshowiframe(false);
                setDisplayChart(false);
                setreportgenerated(false);
                setselectedtestdates([]);
            }
            else {
                setEmptyDate(true);
            }
        }).catch((err) => {
            console.log(err);
            setloaderstatus(false);
            setEmptyDate(true);

            setshowiframe(false);
            setDisplayChart(false);
            setreportgenerated(false);
            setselectedtestdates([]);
        });
    }, [location, props.subherddata])

    useEffect(() => {
        if (reportsettings !== null) {
            let html = gethtmlfromreportsetting(reportsettings, reportResponse, graphimgurlarraydata, null).html;
            sethtmlreport(html);
        }
    }, [reportlayoutsetting])
    
    // ################################################################
    // React.useEffect END
    // ################################################################

    const savepdfbuttonhtml = <Button
        variant="contained"
        className="button"
        type="button"
        size="small"
        onClick={() => {
            savepdf(reportlabel, "pdf");
        }}
    >SAVE A PDF</Button>

    const previewReportButton = <Button
        variant="contained"
        className="button"
        type="button"
        size="small"
        onClick={() => {
            savepdf(reportlabel, "preview");
        }}
    >PREVIEW</Button>

    // ##################################################################
    //   Custom Report Functionality Module and Buttons START
    // ##################################################################

    const savesubherdreportlayouthtml = <Button
        variant="contained"
        className="button"
        type="button"
        size="small"
        onClick={() => {
            setshowsavesubherdreportlayoutconfirmbox(true);
        }}
    >SAVE SUB-HERD REPORT LAYOUT</Button>

    const savereportlayouthtml = <Button
        variant="outlined"
        className="button size-mini"
        type="button"
        size="small"
        onClick={() => {
            handleOpen();
        }}
    >SAVE REPORT LAYOUT</Button>

    const savesubherdreportlayout = () => {
        let requestbody = {
            type: "Subherd",
            sub_herd_id: props.subherddata.sub_herd_id,
            reportlayoutsetting: reportlayoutsetting
        }
        enqueueSnackbar('Saving Subherd Report Layout Setting, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });

        addReportLayout(requestbody).then((response) => {
            enqueueSnackbar('Subherd Report Layout Setting Saved Successfully..', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
            setsubherdreportlayoutcounter(subherdreportlayoutcounter + 1);
        }).catch((err) => {
            console.log(err)
        })
    }

    const savefavouritereportlayout = (name, id, type) => {
        let requestbody = {
            name: name.trim(),
            type: "User",
            reportlayoutsetting: reportlayoutsetting,
            chartlayoutsetting: displayChart ? graphdata : []
        }
        
        if (type !== "new") {
            requestbody.id = id;
            if (type === "rename") {
                requestbody.reportlayoutsetting = myfavouritereportlist.find((d) => d.id === id).reportlayoutsetting
            }
        }
        enqueueSnackbar('Saving Report Layout Setting as Favorite, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });

        addReportLayout(requestbody).then((response) => {
            enqueueSnackbar('Report Layout Setting Saved as Favorite Successfully..', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
            setOpen(false);
            setmyfavouritelistcounter(myfavouritelistcounter + 1)
            setcustomreportnameoverwrite("")
            settobeeditedlayoutid(null);
            setcustomreportname("");
            setreporterrorindex(-1);
            setreporterrorindex(-1);
            setselectedmyfavouritereportsetting("");
        }).catch((err) => {
            setOpen(false);
            settobeeditedlayoutid(null);
            setreporterrorindex(-1);
            selectedmyfavouritereportsetting("");
        })
    }

    const deletemyfavoritelayout = (id) => {
        let beforemessage = 'Deleting ' + myfavouritereportlist.find((d) => d.id === id).name + ' Report Layout, please wait...';
        let aftermessage = myfavouritereportlist.find((d) => d.id === id).name + ' Report Layout deleted Successfully.';

        enqueueSnackbar(beforemessage, { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
        deleteMyFavoriteReportLayout({ id: id }).then((response) => {
            enqueueSnackbar(aftermessage, { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
            setOpen(false);
            setmyfavouritelistcounter(myfavouritelistcounter + 1)
            setselectedmyfavouritereportsetting("");
        }).catch((err) => {
            console.log(err);
            setOpen(false);
            setmyfavouritelistcounter(myfavouritelistcounter + 1)
            setselectedmyfavouritereportsetting("");
        })
    }

    // ##################################################################
    //   Custom Report Functionality Module and Buttons END
    // ##################################################################

    // ##################################################################
    //  Report Generation Functionality Module and Save PDF START
    // ##################################################################

    // Generate pdf HTML 
    const getbeforesectiontabledata = (pdfpage, response, redcoloredsectionsnamearray, sectionname, flag, reportlayoutsetting, setreportlayoutsetting, herd, colorheadingclass, tablesubheadingclass, tableSubHeadlineclass, forPDF) => {
        let sectionnamedata = null;
        let switchforreporting = null;
        let allcows_lactation_header = null;
        let reproductionsectionextrainfodata = null;

        if (pdfpage.page === 1 && sectionname === "All Cows") {
            allcows_lactation_header = <p className={colorheadingclass} style={{ display: "inline-block" }}>Production</p>
        }

        if (["Reproduction"].includes(sectionname) && response.Additional_days && (herd.software_herd_software !== "PCDART")) {
            reproductionsectionextrainfodata = <p className={tableSubHeadlineclass} style={{ display: "inline-block" }}>
                Last Pregnancy Exam Date on Record: {response.Additional_days.Last_Pregnancy_Exam_Date}
            </p>
        }

        // here for checkbox changes
        if (flag) {
            switchforreporting = <Switch size="small" onChange={((event) => {
                let temp = { ...reportlayoutsetting };

                temp.sections.find((d) => d.name === sectionname).show = event.target.checked;
                temp.sections.find((d) => d.name === sectionname).rows = temp.sections.find((d) => d.name === sectionname).rows.map((d) => {
                    return {
                        ...d,
                        show: event.target.checked
                    }
                })
                setreportlayoutsetting(_.cloneDeep(temp));
            })}
                
                checked={reportlayoutsetting.sections.find((d) => d.name === sectionname).show}
            />
        }

        if (redcoloredsectionsnamearray.includes(sectionname)) {
            if (response.Report_Type !== "cullinghistory") {
                sectionnamedata = <p className={colorheadingclass} style={{ display: "inline-block" }}>{(sectionname === "Heifer Enterprise" && response.Report_Type === "heiferreport") ? "Young Animal Enterprise" : sectionname}
                {switchforreporting}</p>
            }
            else {
                sectionnamedata = <span className={ response.Report_Type === "cullinghistory" ? "cullinghistory_header" : null}>{sectionname}
                {switchforreporting}</span>
            }     
        } else if (response.Report_Type === "heiferreport") {
            sectionnamedata = <p className={`${colorheadingclass} ${forPDF ? 'colorheadingclass' : ''}`} style={{ display: "inline-block" }}>{sectionname}
                {switchforreporting}
            </p>
        } else {
            sectionnamedata = <p className={`${tablesubheadingclass} ${forPDF ? 'tablesubheadingclassPDF' : ''}`} style={{ display: "inline-block" }}>{sectionname}
                {switchforreporting}
            </p>
        }

        return <>
            {allcows_lactation_header}
            {sectionnamedata}
            {reproductionsectionextrainfodata}
        </>
    }

    let curMaxRow = 0;
    const getMaxRow = (temp_layout) => {
        // check how many rows
        let maxRowLenghts = 0;
        temp_layout.map((data) => {
            data.sections.map((sections) => {
                if (sections.show) {
                    maxRowLenghts += 1;
                }
                sections.Rows.map((row) => {
                    maxRowLenghts += 1;
                })
            })
        })
        curMaxRow = maxRowLenghts
    }

    const gethtmlfromreportsetting = (setting, response, chartgraph, layoutsetting,  forPDF=false) => {
        let pageno = null;
        let report = response.Report_Type;
        let pagefooter = null;
        let pagefooterdefinition = setting.pagefooterdefinition;
        let footerperpage = setting.footerperpage;
        let lastpagefooter = setting.lastpagefooter;

        let pageclass = "page";
        let reporttableclass = "report_table";
        let pdfheadingclass = "pdfheading";
        let pdfsubheadingclass = "pdfsubheading";
        let colorheadingclass = "coloredheading";
        let tablesubheadingclass = "tableSubHeading";
        let tableSubHeadlineclass = "tableSubHeadline";
        let pagefooterdivclass = "";
        let pdfflagclass = "pdfflag";
        if (!layoutsetting) {
            pageclass = "uipage";
            reporttableclass = "uireport_table";
            pdfheadingclass = "uipdfheading";
            pdfsubheadingclass = "uipdfsubheading";
            colorheadingclass = "uicoloredheading";
            tablesubheadingclass = "uitableSubHeading";
            tableSubHeadlineclass = "uitableSubHeadline";
            pagefooterdivclass = "uipagefooterdiv";
            pdfflagclass = "uipdfflag";
        }
        
        let withAdditionalPage = false;

        let pageheader = (
            <React.Fragment>
                <p className={pdfheadingclass}>{setting.pageheader.reportpreparedfor}</p>
                <span className={pdfsubheadingclass}>
                    {setting.pageheader.report === "Snapshot" ? setting.pageheader.report + " Progress Report" : setting.pageheader.report + " Report"} prepared for <strong>{setting.pageheader.subherd_desc}</strong> by {setting.pageheader.user} on {setting.pageheader.today}
                </span>
            </React.Fragment>
        );

        let flag = customizationallowedreport.includes(report) && layoutsetting === null ? true : false;

        let todaydate = new Date();
        let today = moment(todaydate).format("l");
        let todaytime = moment(todaydate).format("h:mm a");
        let herd = props.herddata;
        
        let settings = _.cloneDeep(setting.settings); //deep clone
        let heifer_2nd_settings = []
        if (report === "heiferreport" && source_system === "DC305")
            heifer_2nd_settings = _.cloneDeep(setting.heifer_2nd_settings); //deep clone
        let pagewidthstyle = {}

        if (layoutsetting !== null && customizationallowedreport.includes(report)) {
            // filter out the section from the page
            settings.forEach((page) => {
                page.sections = page.sections.filter((section) => {
                    let sectionname = getsectionname(section.Section_Name);
                    return layoutsetting.sections.find((d) => d.name === sectionname).show;

                })
            })
            // filterout the page if the section is empty in the page
            settings = settings.filter((page) => {
                return page.sections.length > 0;
            })

            //Adjust the page numbering
            settings = settings.map((data, index) => {
                return {
                    ...data,
                    page: index + 1
                }
            })

            // filterout the rows from section
            settings.forEach((page) => {
                page.sections.forEach((section) => {
                    section.Rows = section.Rows.filter((row) => {
                        let sectionname = getsectionname(section.Section_Name);
                        return layoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show;
                    })
                })
            })
            
            let maxtable_rows = 41;
            let after1page_maxrow = 0;
            if (report === "snapshot") {
                maxtable_rows = 39;
                after1page_maxrow = 37;
            } else if(report === "benchmark") {
                maxtable_rows = 39;
                after1page_maxrow = 36;
            }else if(report === "heiferreport") {
                maxtable_rows = 42;
                after1page_maxrow = 40;
            } else {
                maxtable_rows = 41;
                after1page_maxrow = 39;
            }
            settings = _.cloneDeep(getPdfDynamicSettings(settings, maxtable_rows, after1page_maxrow))
            if (report === "heiferreport" && source_system === "DC305")

                // filter out the section from the page
                heifer_2nd_settings.forEach((page) => {
                    page.sections = page.sections.filter((section) => {
                        let sectionname = getsectionname(section.Section_Name);
                        return layoutsetting.sections.find((d) => d.name === sectionname).show;

                    })
                })
                // filterout the page if the section is empty in the page
                heifer_2nd_settings = heifer_2nd_settings.filter((page) => {
                    return page.sections.length > 0;
                })

                //Adjust the page numbering
                heifer_2nd_settings = heifer_2nd_settings.map((data, index) => {
                    return {
                        ...data,
                        page: index + 1
                    }
                })

                // filterout the rows from section
                heifer_2nd_settings.forEach((page) => {
                    page.sections.forEach((section) => {
                        section.Rows = section.Rows.filter((row) => {
                            let sectionname = getsectionname(section.Section_Name) + " By Month";
                            return layoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show;
                        })
                    })
                })
                
                heifer_2nd_settings = _.cloneDeep(getdynamicsettings(heifer_2nd_settings, 37, report, "view", graphimgurlarraydata))
        } else {
            pagewidthstyle = {}
            let toalrowperpage = report === "cullinghistory" ? 37 : 33;
            settings = _.cloneDeep(getdynamicsettings(settings, toalrowperpage, report, "view", graphimgurlarraydata));
            if (report === "heiferreport" && source_system === "DC305")
                heifer_2nd_settings = _.cloneDeep(getdynamicsettings(heifer_2nd_settings, 30, report, "view", graphimgurlarraydata));
        }

        getMaxRow(settings);

        if (settings.length === 1) {
            const additionalSection = {
                page: settings.length + 1,
                sections: [],
            }
            if(report === "snapshot") {
                if (curMaxRow > 33 && curMaxRow < 39) {
                    withAdditionalPage = true;
                    settings.push(additionalSection)
                }
            } else if (report === "benchmark") {
                if (curMaxRow > 30 && curMaxRow < 39) {
                    withAdditionalPage = true;
                    settings.push(additionalSection)
                }
            } else {
                withAdditionalPage = false;
            }
        } else {
            withAdditionalPage = false;
        }

        // check period comparison if only one date for each period is selected
        let period_1 = response.TestDates.test_day_1
        let period_2 = response.TestDates.test_day_2
        if (report === 'periodcompare') {
            const temp_period_1 = response.TestDates.test_day_1 ? response.TestDates.test_day_1.split(" - ") : null;
            const temp_period_2 = response.TestDates.test_day_2 ? response.TestDates.test_day_2.split(" - ") : null;
            period_1 = temp_period_1[0] === temp_period_1[1] ? temp_period_1[0] : response.TestDates.test_day_1;
            period_2 = temp_period_2[0] === temp_period_2[1] ? temp_period_2[0] : response.TestDates.test_day_2;
        }
        
        let columnheading = (pageIndexNumber = 0) => (
            <thead className={`reporthead ${(withAdditionalPage && pageIndexNumber > 0) ? 'noheadings' : ''}`}>
                <tr>
                    {
                        report === "cullinghistory" ? <th style={{ width: "22%" }}></th> : <th></th>
                    }
                    {
                        report === 'periodcompare' ? 
                            <>
                                <th>{response.TestDates ? period_1 : ''}</th>
                                <th>{response.TestDates ? period_2 : ''}</th>
                                <th className="coloredcell">Herd Goal</th>
                            </>
                        :
                        response.colDef.map((column, index) => {
                            let colstyle =
                                column.col_name === "Herd_Goal" || column.col_name === "Flag"
                                    ? "coloredcell"
                                    : null;
                            return <React.Fragment key={index}>{
                                report === "cullinghistory" ? <th className={colstyle} style={{ width: "auto" }}>
                                    {column.col_header === "Total" && report === "cullinghistory" ? "12 Mos" : column.col_header}
                                </th> : <th className={colstyle}>
                                        {column.col_header === "Total" && report === "cullinghistory" ? "12 Mos" : column.col_header}
                                    </th>
                            }
                            </React.Fragment>;
                        })
                    }

                    {flag ? <th width="5%">Action</th> : null}
                </tr>
            </thead>
        );

        let totalpage = settings.length;
        let chartpagesetting = setting.chartpagesetting;

        chartpagesetting = new_getcondensedchartpagesetting(chartpagesetting);

        totalpage += chartpagesetting.length;
        if (report === "heiferreport" && source_system === "DC305") {
            totalpage += heifer_2nd_settings.length;
        }
        
        let reportingcontent = settings.map((pdfpage, index) => {
            pageno = pdfpage.page;
            pagefooter = <>
                {
                    (layoutsetting !== null && layoutsetting !== undefined) ? <div className="footer">
                        <div>
                            Page {pageno} of {totalpage} | Report generated {today}{" "}
                                at {todaytime} for {setting.pageheader.reportpreparedfor} - {setting.pageheader.subherd_desc}
                        </div>
                    </div> : null
                }
            </>
            let colspannum = flag ? 2 : 1;

            if (report !== "cullinghistory") {
                if (!table_headers.includes("Number_Culled_Dry")) {
                    table_headers.push("Number_Culled_Dry");
                }
            }
            if (report === "cullinghistory") {
                let showAttributes = ["Number_Culled_Dry"];
                for(const element of showAttributes) {
                    let idx = table_headers.indexOf(element);
                    if (idx > -1) {
                        table_headers.splice(idx, 1);
                    }
                }
            }
            return (
                <div className={pageclass} style={pagewidthstyle} key={index}>
                    {pdfflagclass === "pdfflag" ? (index === 0 ? pageheader : null) : pageheader}
                    <table className={reporttableclass} style={{ marginBottom: "0px" }}>
                        {/*show column heading only on the first table of the page*/}
                        {columnheading(index)}
                        <tbody className="reporttbody">
                            {pdfpage.sections.map((section, i) => {
                                let sectionname = section.Section_Header;
                                let rowdata = (
                                    <>
                                        {section.Rows.map((row, rowindex) => {
                                            // gets the normal/firstlevel/secondlevel first data and rows corressponding style
                                            let obj = getrowheadercontent(row, section)
                                            if(report === "periodcompare" && source_system === "DC305" && (row.Row_Header === "Last Test Day on Record" || row.Row_Header === "Last Pregnancy Exam Date on Record")) {
                                                return null;
                                            }
                                            let rowheadercontent = obj.rowheadercontent;
                                            let trclassname = obj.trclassname;
                                            return (
                                                <tr key={rowindex} className={trclassname}>
                                                    {
                                                        !table_headers.includes(row.Row_Name) ? <>
                                                            <td>{rowheadercontent}</td>
                                                        </> : 
                                                            row_subheader.includes(row.Row_Name) ? <>
                                                                <td colSpan={response.colDef.length + (flag ? colspannum - 1 : colspannum)}
                                                                        style={{ border: "1px solid #adadad", color: "#000000" }}
                                                                >{rowheadercontent}</td>
                                                            </> : <>
                                                                <td colSpan={response.colDef.length + (flag ? colspannum - 1 : colspannum)}
                                                                    style={{ border: "1px solid #adadad", color: "#000000", fontWeight: "bold" }}
                                                                >{rowheadercontent}</td>
                                                            </>
                                                    }

                                                    {
                                                    !table_headers.includes(row.Row_Name) ? <React.Fragment>{
                                                        response.colDef.map((column, colindex) => {
                                                            let obj = getcelldata(row, column, rowindex, response, report, pdfflagclass);
                                                            let celldata = obj.celldata;
                                                            if (row.Row_Header === "Last Test Day on Record") {
                                                                if (obj.celldata === null || obj.celldata === 0) {
                                                                    celldata = '';
                                                                }
                                                            }
                                                            let dashstyle = obj.dashstyle
                                                            return <td key={colindex} className={dashstyle}>{celldata}</td>;
                                                        })

                                                    }
                                                    {
                                                        // Switch on all row for user report layout setting.
                                                        flag ? <td>
                                                            <Switch size="small" onChange={((event) => {
                                                                let temp = { ...reportlayoutsetting };

                                                                if (temp.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)) {
                                                                    temp.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name).show = event.target.checked;
                                                                } else {
                                                                    temp.sections.find((d) => d.name === sectionname).rows.push({
                                                                        name: row.Row_Name,
                                                                        show: event.target.checked
                                                                    })
                                                                }

                                                                if (event.target.checked)
                                                                    temp.sections.find((d) => d.name === sectionname).show = true

                                                                setreportlayoutsetting(_.cloneDeep(temp));
                                                            })}
                                                                checked={reportlayoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show === undefined ? false 
                                                                    : reportlayoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show}
                                                            />
                                                        </td> : null
                                                    }
                                                    </React.Fragment> : <>
                                                        {
                                                            // Switch on all row for user report layout setting.
                                                            flag ? <td>
                                                                <Switch size="small" onChange={((event) => {
                                                                    let temp = { ...reportlayoutsetting };

                                                                    if (temp.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)) {
                                                                        temp.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name).show = event.target.checked;
                                                                    } else {
                                                                        temp.sections.find((d) => d.name === sectionname).rows.push({
                                                                            name: row.Row_Name,
                                                                            show: event.target.checked
                                                                        })
                                                                    }

                                                                    if (event.target.checked)
                                                                        temp.sections.find((d) => d.name === sectionname).show = true

                                                                    setreportlayoutsetting(_.cloneDeep(temp));
                                                                })}
                                                                    checked={reportlayoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show === undefined ? false : reportlayoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show}
                                                                />
                                                            </td> : null
                                                        }
                                                    </>
                                                    }
                                                </tr>
                                            );
                                        })}
                                    </>
                                );
                                
                                return (
                                    <React.Fragment key={i}>
                                        {
                                            section?.show ? <tr style={{ backgroundColor: "#ffffff" }}>
                                                <td colSpan={response.colDef.length + colspannum} style={{ border: "transparent" }}>
                                                    {getbeforesectiontabledata(pdfpage, response, redcoloredsectionsnamearray, sectionname, flag, reportlayoutsetting, setreportlayoutsetting, herd, colorheadingclass, tablesubheadingclass, tableSubHeadlineclass, forPDF)}
                                                </td>
                                            </tr> : null
                                        }
                                        {rowdata}
                                    </React.Fragment>
                                );
                            })}
                        </tbody>
                    </table>
                    {forPDF 
                        ? pdfpage.page === settings.length
                            ? <div className={pagefooterdivclass}>
                                {pagefooterdefinition}  
                                {lastpagefooter}
                            </div> 
                            : <div className={pagefooterdivclass}>{footerperpage}</div> 
                        : pdfpage.page === totalpage ?
                            <div className={pagefooterdivclass}>
                                {pagefooterdefinition}
                                {lastpagefooter}
                            </div> : null
                    }
                    {pagefooter}
                </div>
            );
        })

        // heifer 2nd report here
        let heifer_2nd_content = [];
        let months_header_ctr = response.Health_Culling_History?.colDef.length;
        if (report === "heiferreport" && reporttab === "heiferreport" && source_system === "DC305") {
            let second_columnheading = (
                <thead className={`reporthead ${withAdditionalPage ? 'noheadings' : ''}`}>
                    <tr>
                        <th style={{ width: forPDF ? "35%" : "30%" }}></th>
                        {
                            response.Health_Culling_History?.colDef?.map((column, index) => {
                                let colstyle =
                                    column.col_name === "Herd_Goal" || column.col_name === "Flag"
                                        ? "coloredcell"
                                        : null;
                                return <React.Fragment key={index}>{
                                    <th className={colstyle} style={{ width: "auto" }}>
                                        {column.col_header === "Quarter Average" ? "12 Mos" : column.col_header}
                                    </th> 
                                }
                                </React.Fragment>;
                            })
                        }
    
                        {flag ? <th width="5%">Action</th> : null}
                    </tr>
                </thead>
            );
                        
            heifer_2nd_content = heifer_2nd_settings.map((pdfpage2, index) => {
                pageno = settings.length + pdfpage2.page;
                pagefooter = <>
                    {
                        (layoutsetting !== null && layoutsetting !== undefined) ? <div className="footer">
                            <div>
                                Page {pageno} of {totalpage} | Report generated {today}{" "}
                                    at {todaytime} for {setting.pageheader.reportpreparedfor} - {setting.pageheader.subherd_desc}
                            </div>
                        </div> : null
                    }
                </>
                let colspannum = months_header_ctr;

                return (
                    <div className={pageclass} style={pagewidthstyle} key={index}>
                        {pdfflagclass === "pdfflag" ? (index === 0 ? pageheader : null) : pageheader}
                        <table className={reporttableclass} style={{ marginBottom: "0px" }}>
                            {second_columnheading}
                            <tbody className="reporttbody">
                                {pdfpage2.sections.map((section, i) => {
                                    let sectionname = section.Section_Header + " By Month";
                                    let rowdata = (
                                        <>
                                            {section.Rows.map((row, rowindex) => {
                                                // gets the normal/firstlevel/secondlevel first data and rows corressponding style
                                                let obj = getrowheadercontent(row, section)
                                                if(report === "periodcompare" && source_system === "DC305" && (row.Row_Header === "Last Test Day on Record" || row.Row_Header === "Last Pregnancy Exam Date on Record")) {
                                                    return null;
                                                }
                                                let rowheadercontent = obj.rowheadercontent;
                                                let trclassname = obj.trclassname;
                                                return (
                                                    <tr key={rowindex} className={trclassname}>
                                                        {
                                                            !table_headers.includes(row.Row_Name) ? <>
                                                                <td>{rowheadercontent}</td>
                                                            </> : 
                                                                    row_subheader.includes(row.Row_Name) ? <>
                                                                    <td colSpan={months_header_ctr + 1}
                                                                        style={{ border: "1px solid #adadad", color: "#000000" }}
                                                                    >{rowheadercontent}</td>
                                                            </> : <>
                                                                <td colSpan={months_header_ctr + 1}
                                                                    style={{ border: "1px solid #adadad", color: "#000000", fontWeight: "bold" }}
                                                                >{rowheadercontent}</td>
                                                            </>
                                                        }

                                                        {
                                                            !table_headers.includes(row.Row_Name) ? <React.Fragment>{
                                                                response.Health_Culling_History.colDef.map((column, colindex) => {
                                                                    let obj = getcelldata(row, column, rowindex, response, report, pdfflagclass);
                                                                    let celldata = obj.celldata;
                                                                    if (row.Row_Header === "Last Test Day on Record") {
                                                                        if (obj.celldata === null || obj.celldata === 0) {
                                                                            celldata = '';
                                                                        }
                                                                    }
                                                                    let dashstyle = obj.dashstyle
                                                                    return <td key={colindex} className={dashstyle}>{celldata}</td>;
                                                                })

                                                            }
                                                                {
                                                                    // Switch on all row for user report layout setting.
                                                                    flag ? <td>
                                                                        <Switch size="small" onChange={((event) => {
                                                                            let temp = { ...reportlayoutsetting };

                                                                            if (temp.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)) {
                                                                                temp.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name).show = event.target.checked;
                                                                            } else {
                                                                                temp.sections.find((d) => d.name === sectionname).rows.push({
                                                                                    name: row.Row_Name,
                                                                                    show: event.target.checked
                                                                                })
                                                                            }

                                                                            if (event.target.checked)
                                                                                temp.sections.find((d) => d.name === sectionname).show = true

                                                                            setreportlayoutsetting(_.cloneDeep(temp));
                                                                        })}
                                                                            checked={reportlayoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show === undefined ? false : reportlayoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show}
                                                                        />
                                                                    </td> : null
                                                                }
                                                            </React.Fragment> : <>
                                                                    {
                                                                        // Switch on all row for user report layout setting.
                                                                        flag ? <td>
                                                                            <Switch size="small" onChange={((event) => {
                                                                                let temp = { ...reportlayoutsetting };

                                                                                if (temp.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)) {
                                                                                    temp.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name).show = event.target.checked;
                                                                                } else {
                                                                                    temp.sections.find((d) => d.name === sectionname).rows.push({
                                                                                        name: row.Row_Name,
                                                                                        show: event.target.checked
                                                                                    })
                                                                                }

                                                                                if (event.target.checked)
                                                                                    temp.sections.find((d) => d.name === sectionname).show = true

                                                                                setreportlayoutsetting(_.cloneDeep(temp));
                                                                            })}
                                                                                checked={reportlayoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show === undefined ? false : reportlayoutsetting.sections.find((d) => d.name === sectionname).rows.find((k) => k.name === row.Row_Name)?.show}
                                                                            />
                                                                        </td> : null
                                                                    }
                                                                </>
                                                        }
                                                    </tr>
                                                );
                                            })}
                                        </>
                                    )

                                    return (
                                        <React.Fragment key={i}>
                                            {

                                                section?.show ? <tr style={{ backgroundColor: "#ffffff" }}>
                                                    <td colSpan={colspannum + 1} style={{ border: "transparent" }}>
                                                        {getbeforesectiontabledata(pdfpage2, response, redcoloredsectionsnamearray, sectionname, flag, reportlayoutsetting, setreportlayoutsetting, herd, colorheadingclass, tablesubheadingclass, tableSubHeadlineclass, forPDF)}
                                                    </td>
                                                </tr> : null
                                            }
                                            {rowdata}
                                        </React.Fragment>
                                    );
                                })}
                            </tbody>
                        </table>
                        {forPDF 
                        ? pdfpage2.page === heifer_2nd_settings.length 
                            ? <div className={pagefooterdivclass}>
                                {pagefooterdefinition}  
                                {lastpagefooter}
                            </div> 
                            : <div className={pagefooterdivclass}>{footerperpage}</div> 
                        : pdfpage2.page === heifer_2nd_settings.length ?
                            <div className={pagefooterdivclass}>
                                {pagefooterdefinition}
                                {lastpagefooter}
                            </div> : null
                        }
                        {pagefooter}
                    </div>
                );
            });
        }

        if (report === "periodcompare") {
            pageno = 0;
            totalpage = chartpagesetting.length;
        }
        
        let chartcontent = chartpagesetting.map((chartpage, chartindex) => {
            let pagefooter = <>{
                (layoutsetting !== null && layoutsetting !== undefined) ? <div className="footer">
                    <div>
                        Page {pageno + 1} of {totalpage} | Report generated {today}{" "}
                            at {todaytime} for {setting.pageheader.reportpreparedfor} - {setting.pageheader.subherd_desc}
                    </div>
                </div> : null
            }</>
            if (chartindex === chartpagesetting.length - 1) {
                let newChartPage = chartpage.chart
                if(["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison"].includes(reporttab)) {
                    newChartPage = chartpage.chart.filter(item => item.id !== "lactationcurvechart");
                }
                return (
                    <div className={pageclass} key={chartindex}>
                        {pdfflagclass === "pdfflag" ? (chartindex === 0 ? pageheader : null) : pageheader}
                        {newChartPage.map((graph, index) => {
                            let heading = null;
                            switch (graph.width) {
                                case "full_page":
                                    heading = <h2>{graph.name === "Test Day Milk" || graph.name === "Weekly Milk" ? "Milk Production" : graph.name}</h2>
                                    break;
                                case "half_page":
                                    heading = <h4>{graph.name === "Test Day Milk" || graph.name === "Weekly Milk" ? "Milk Production" : graph.name}</h4>
                                    break;
                                default:
                                    heading = <h6>{graph.name === "Test Day Milk" || graph.name === "Weekly Milk" ? "Milk Production" : graph.name}</h6>
                            }
                            return (
                                <React.Fragment key={index}>
                                    <div className={graph.className}>
                                        {heading}
                                        <img
                                            src={graph.bloburl}
                                            className="graphimage"
                                            alt={graph.name}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        })}
                        <div className={pagefooterdivclass}>
                            {lastpagefooter}
                        </div> 
                        {pagefooter}
                    </div>
                );
            } else {
                pageno += 1;
                return (
                    <div className={pageclass} key={chartindex}>
                        {pdfflagclass === "pdfflag" ? (chartindex === 0 ? pageheader : null) : pageheader}
                        {chartpage.chart.map((graph, index) => {
                            let heading = null;
                            switch (graph.width) {
                                case "full_page":
                                    heading = <h2>{graph.name === "Test Day Milk" || graph.name === "Weekly Milk" ? "Milk Production" : graph.name}</h2>
                                    break;
                                case "half_page":
                                    heading = <h4>{graph.name === "Test Day Milk" || graph.name === "Weekly Milk" ? "Milk Production" : graph.name}</h4>
                                    break;
                                default:
                                    heading = <h6>{graph.name === "Test Day Milk" || graph.name === "Weekly Milk" ? "Milk Production" : graph.name}</h6>
                            }
                            return (
                                <React.Fragment key={index}>
                                    <div className={graph.className}>
                                        {heading}
                                        <img
                                            src={graph.bloburl}
                                            className="graphimage"
                                            alt={graph.name === "Test Day Milk" || graph.name === "Weekly Milk" ? "Milk Production" : graph.name}
                                        />
                                    </div>
                                </React.Fragment>
                            );
                        })}
                        {forPDF ? <div className={pagefooterdivclass}>{footerperpage}</div> : ''}
                        {pagefooter}

                    </div>
                );
            }
        })
        return {

            html: <div id="pdf_container" style={{ textAlign: "left" }}>
                {reportingcontent}
                {report === "heiferreport" && source_system === "DC305" ? heifer_2nd_content : ''}
                {chartgraph.length > 0 ? (
                    <React.Fragment>
                        {chartcontent}
                    </React.Fragment>
                ) : null}
            </div>,
            totalpage: totalpage
        }
    }

    const generatereport = (data) => {
        if (displayChart) {
            reportgeneration(data, "reportbutton", null);
        } else {
            reportgeneration(data, "reportbutton", null);
        }
    }

    const reportgeneration = (data, typecall, temp) => {
        if (typecall === "reportbutton") {
            setshowiframe(false);
        }
        let flag = true;
        let reporterrorsubmission = "Please Select all testdates required for ";

        let reportdatelength = temp !== null ? selectedtestdates.length + 1 : selectedtestdates.length;
        if (selectedtestdates.length === 0 || reportdatelength < reporttotaltestdays[data.name])
            flag = false;


        selectedtestdates.forEach((d) => {
            if (d === "" || d === null)
                flag = false;
        })

        if (temp !== null) {
            setselectedtestdates(temp);
        }

        if (flag || ["cullinghistory", "snapshotgoals"].includes(data.name)) {
            setloaderstatus(true);
            let requestbody = {};

            if (data.name === "cullinghistory" || data.name === "snapshotgoals") {
                let testday = reporttesdays[0];
                requestbody = {
                    'sub_herd_id': uploaddatadetails[0].sub_herd_id,
                    'test_day': testday,
                    'source_system': uploaddatadetails[0].source_system
                };
            } else {
                requestbody = {
                    'sub_herd_id': uploaddatadetails[0].sub_herd_id,
                    'test_day': selectedtestdates[0],
                    'source_system': uploaddatadetails[0].source_system
                };
            }


            if (typecall === "reportbutton") {
                enqueueSnackbar('Generating PDF, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
            } else {
                enqueueSnackbar('Getting Chart Data, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
            }

            let report_type = "", report_function = {};
            switch (data.name) {
                case "benchmark":
                    report_type = REPORT_TYPE.BENCHMARK_SNAPSHOT;
                    report_function = { func: generateBenchmarkReport, name: "Benchmark" };
                    break;
                case "snapshot":
                    report_type = REPORT_TYPE.SNAPSHOT_REPORT;
                    report_function = { func: generateSnapshotReport, name: "Snapshot" };
                    break;
                case "snapsix":
                    report_type = REPORT_TYPE.SNAPSIX_REPORT;
                    report_function = { func: generateSnapsixReport, name: "SnapSix" };
                    break;
                case "periodcomparison":
                    report_type = REPORT_TYPE.PERIODCOMPARISON_REPORT;
                    report_function = { func: generatePeriodComparisonReport, name: "Period Comparison" };
                    requestbody.test_day = temp !== null ? temp.join(",") : selectedtestdates.join(",")
                    break;
                case "cullinghistory":
                    report_type = REPORT_TYPE.CULLINGHISTORY_REPORT;
                    report_function = { func: generateCullingHistoryReport, name: "Culling History" };
                    break;
                case "snapshotgoals":
                    report_type = REPORT_TYPE.SNAPSHOTGOAL_REPORT;
                    report_function = { func: generateSnapshotGoalReport, name: "Snapshot Goal" };
                    break;
                case "subherdcomparison":
                    report_type = REPORT_TYPE.SUBHERDCOMPARISON_REPORT;
                    report_function = { func: generateSubherdComparisonReport, name: "Sub-herd Comparison"};
                    break;
                case "heiferreport":
                    report_type = REPORT_TYPE.HEIFER_REPORT;
                    report_function = { func: generateHeiferReport, name: "Young Animal"};
                    break;
                default:
                    report_type = REPORT_TYPE.BENCHMARK_SNAPSHOT;
                    report_function = { func: generateBenchmarkReport, name: "Benchmark" };
                    break;
            }

            callapi(requestbody, report_type, data, report_function, typecall, temp);
        } else {
            let error_message = reporterrorsubmission + data.label;
            if (data.name === "periodcomparison") {
                error_message = "Please select test dates for both Period 1 and Period 2."
            }
            setReportError(error_message);
            setshowReportError(true);
        }
    }

    const updateGraphData = () => {
        const updatedChartData = [];

        selectedGraph.map((graphX) => {
            let classSize = "";
            switch(graphX.width) {
                case "full_page":
                    classSize = "reportfullpage";
                    break;
                case "half_page":
                    classSize = "reporthalfpage";
                    break;
                case "one_third":
                    classSize = "reportonethirdpage";
                    break;

                default:
                    break;
            }

            const updatedItem = graphimgurlarraydata.find(item => item.id === graphX.id);
            if (updatedItem) {
                updatedItem.width = graphX.width;
                updatedItem.className = classSize;
                updatedChartData.push(updatedItem);
            }
        })
        
        return updatedChartData;
    }

    const callapi = (requestbody, report_type, data, report_function, typecall, temp) => {
        if (reportdataresponse === null || temp != null) {
            // Report API Calling
            getReportResponse(requestbody, report_type).then(response => {
                let decodedResponse = decodeApiResponse(response);
                setReportResponse(decodedResponse);
                decodedResponse.Report_Type = data.name;
                decodedResponse.Sections = decodedResponse.Sections.map((section => {
                    if (["Lactation_1", "Lactation_2", "Lactation_3"].includes(section.Section_Name) && !["snapshot", "benchmark"].includes(data.name)) {
                        if (data.name === "snapsix") {
                            section.Rows = section.Rows.map(row => {
                                delete row?.Row_val?.HD;
                                delete row?.Row_val?.DIM;
                                return row
                            })
                        }
                    }
                    return section;
                }))
                
                // Chart API Calling
                if (report_type === "culling-report") {
                    if (typecall === "reportbutton") {
                        decodedResponse.graphselected = selectedGraph;
                        let setting = report_function.func(decodedResponse, graphimgurlarraydata, props.herddata, props.subherddata);
                        sethtmlreport(gethtmlfromreportsetting(setting, decodedResponse, graphimgurlarraydata, null, false).html);
                        setreportsettings(setting);
                        setshowiframe(true);
                        setreportgenerated(true);
                        setloaderstatus(false);
                        enqueueSnackbar('Pdf Generated..', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                    }
                } else if (report_type === "heifer-report")  {
                    if (typecall === "reportbutton") {
                        decodedResponse.graphselected = selectedGraph;
                        let setting = report_function.func(decodedResponse, graphimgurlarraydata, props.herddata, props.subherddata);
                        sethtmlreport(gethtmlfromreportsetting(setting, decodedResponse, graphimgurlarraydata, null, false).html);
                        if (report_type === "heifer-report" && requestbody.source_system === "DC305")
                            setHeiferCulling_htmlreport(gethtmlfromreportsetting(setting, decodedResponse, graphimgurlarraydata, null, false).html);
                        setreportsettings(setting);
                        setshowiframe(true);
                        setreportgenerated(true);
                        setloaderstatus(false);
                        enqueueSnackbar('Pdf Generated..', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                    }
                } else {
                    if (decodedResponse.Report_Type === "periodcomparison")
                        decodedResponse.Report_Type = "periodcompare"

                    if (decodedResponse.Report_Type !== "snapshotgoals") {
                        postSnapshotGraphsUrl(decodedResponse).then((graphResult) => {
                            const newGraphResult = decodeApiResponse(graphResult);
                            let lactationcurvemaxytemp = 140;
                            let lactationcurveminytemp = 0;
                            if (reporttab !== "snapsix") {

                                try {
                                    for (let k in newGraphResult) {
                                        //Replacing the " " with 0 from the Backend graphdata response for All Graph
                                        newGraphResult[k] = newGraphResult[k].map((d) => {
                                            for (let dk in d) {
                                                if (dk !== "categories") {
                                                    let checkperiod = -1;
                                                    d[dk].map((nd) => {
                                                        if (k === "somatic_cell_count") {
                                                            //replace <50 etc with numeric values 1,2-3 etc
                                                            switch (nd.header) {
                                                                case "<50":
                                                                    nd.header = "<2";
                                                                    break;
                                                                case "200":
                                                                    nd.header = "2-3";
                                                                    break;
                                                                case "400":
                                                                    nd.header = "4";
                                                                    break;
                                                                case "800":
                                                                    nd.header = "5";
                                                                    break;
                                                                case ">800":
                                                                default:
                                                                    nd.header = "6+";

                                                            }
                                                        }

                                                        if (props.reporttab === "periodcomparison" && !bargraph.includes(k)) {
                                                            let period = "Period II ";

                                                            if (checkperiod === -1) {
                                                                checkperiod += 1;
                                                                period = "Period I ";
                                                            }

                                                            period += " (" + moment(nd.header.substring(0, 10)).format("MM/DD/YY") + " - " + moment(nd.header.substring(11)).format("MM/DD/YY") + ")";
                                                            nd.header = period;
                                                            nd.name = period;
                                                        }

                                                        if (nd.data) {
                                                            nd.data = nd.data.map((val) => {
                                                                if (val === " ")
                                                                    return 0;
                                                                return val
                                                            })
                                                        }
                                                        return nd;
                                                    })
                                                } else {
                                                    d["categories"] = d["categories"].map((data, i) => {
                                                        switch (data) {
                                                            case "Week 2":
                                                                data = 2;
                                                                break;
                                                            case "Week 4":
                                                                data = 4;
                                                                break;
                                                            case "Week 8":
                                                                data = 8;
                                                                break;
                                                            case "Week 15":
                                                                data = 15;
                                                                break;
                                                            case "Week 21":
                                                                data = 21;
                                                                break;
                                                            case "Week 25":
                                                                data = 25;
                                                                break;
                                                            case "Week 32":
                                                                data = 32;
                                                                break;
                                                            case "Week 37":
                                                                data = 37;
                                                                break;
                                                            default:
                                                                data = data;
                                                        }
                                                        return data;
                                                    })
                                                }
                                            }
                                            return d
                                        })
                                        if (bargraph.includes(k)) {
                                            //Flipping the legend and X-axis from the Backend graphdata response for BAR GRAPH
                                            newGraphResult[k] = newGraphResult[k].map((d) => {
                                                let temp = {};
                                                let categories = [];
                                                let data = {};
                                                let key1 = null;
                                                d.categories.forEach((val, i) => {
                                                    data[i] = []
                                                })
                                                let checkperiod = -1;
                                                d.categories = d.categories.map((val, i) => {
                                                    if (props.reporttab === "periodcomparison" && val !== "Herd Goal") {
                                                        let period = "Period II ";

                                                        if (checkperiod === -1) {
                                                            checkperiod += 1;
                                                            period = "Period I ";
                                                        }
                                                        period += " (" + moment(val.substring(0, 10)).format("MM/DD/YY") + " - " + moment(val.substring(11)).format("MM/DD/YY") + " )";
                                                        val = period
                                                    }
                                                    return val;
                                                })

                                                for (let dk in d) {
                                                    if (dk !== "categories") {
                                                        key1 = dk;
                                                        d[dk].forEach((nd) => {
                                                            categories.push(nd.header);
                                                            d.categories.forEach((val, i) => {
                                                                data[i].push(nd.data[i] === undefined ? 0 : nd.data[i]);
                                                            })
                                                        })
                                                    }
                                                }
                                                temp[key1] = [];
                                                d.categories.forEach((val, i) => {
                                                    temp[key1].push({
                                                        data: data[i],
                                                        header: val,
                                                        name: val
                                                    })
                                                })
                                                temp["categories"] = categories;
                                                return temp;
                                            })
                                        }
                                    }

                                    if (props.reporttab === "snapshot") {
                                        let maxdata = []
                                        props.reportResponse.Lacation_graph_values.Goal_data = props.reportResponse.Lacation_graph_values.Goal_data.map((d) => {
                                            maxdata = [...maxdata, ...d.data];
                                            if (d.name[d.name.length - 1] === "l") {
                                                d.name = d.name.replace("Lactation", "Lact").replace("_", " ")
                                            } else {
                                                d.name = d.name.replace("Lactation", "Lact")
                                            }
                                            return d;
                                        })

                                        for (let k in newGraphResult) {
                                            if (["lacation_1_data", "lacation_2_data", "lacation_3_data"].includes(k)) {
                                                let key = k.replace("lacation", "lactation");
                                                key = key[0].toUpperCase() + key.slice(1);
                                                for (let i = 0; i < newGraphResult[k][0][key].length; i++) {
                                                    maxdata = [...maxdata, ...newGraphResult[k][0][key][i].data]
                                                }
                                            }
                                        }
                                        maxdata = maxdata.filter(n => n);
                                        lactationcurvemaxytemp = Math.max(...maxdata) + 10;
                                        lactationcurveminytemp = Math.min(...maxdata) - 10;
                                    } else {
                                        let maxdata = []
                                        for (let k in newGraphResult) {
                                            if (["lacation_1_data", "lacation_2_data", "lacation_3_data"].includes(k)) {
                                                let key = k.replace("lacation", "lactation");
                                                key = key[0].toUpperCase() + key.slice(1);
                                                for (let i = 0; i < newGraphResult[k][0][key].length; i++) {
                                                    if (newGraphResult[k][0][key][i].data)
                                                        maxdata = [...maxdata, ...newGraphResult[k][0][key][i].data]
                                                }
                                            }
                                        }
                                        maxdata = maxdata.filter(n => n);
                                        lactationcurvemaxytemp = Math.max(...maxdata) + 10;
                                        lactationcurveminytemp = Math.min(...maxdata) - 10;
                                    }
                                } catch (error) {
                                    console.log(error)
                                }

                            } else {
                                let maxdata = [];
                                for (let k in newGraphResult) {
                                    if (["lacation_1_data", "lacation_2_data", "lacation_3_data"].includes(k)) {
                                        let key = k.replace("lacation", "lactation");
                                        key = key[0].toUpperCase() + key.slice(1);
                                        for (let i = 0; i < newGraphResult[k][0][key].length; i++) {
                                            maxdata = [...maxdata, ...newGraphResult[k][0][key][i].data]
                                        }
                                    }
                                    newGraphResult[k] = newGraphResult[k].map((d) => {
                                        d["categories"] = d["categories"].map((data, i) => {
                                            switch (data) {
                                                case "Week 2":
                                                    data = 2;
                                                    break;
                                                case "Week 4":
                                                    data = 4;
                                                    break;
                                                case "Week 8":
                                                    data = 8;
                                                    break;
                                                case "Week 15":
                                                    data = 15;
                                                    break;
                                                case "Week 21":
                                                    data = 21;
                                                    break;
                                                case "Week 25":
                                                    data = 25;
                                                    break;
                                                case "Week 32":
                                                    data = 32;
                                                    break;
                                                case "Week 37":
                                                    data = 37;
                                                    break;
                                                default:
                                                    data = data;
                                            }
                                            return data;
                                        })
                                        return d;
                                    })
                                }
                                maxdata = maxdata.filter(n => n);
                                lactationcurvemaxytemp = Math.max(...maxdata) + 10;
                                lactationcurveminytemp = Math.min(...maxdata) - 10;

                                //Sort data in Descending Order of dates provided
                                for (let k in newGraphResult) {
                                    if (bargraph.includes(k)) {
                                        newGraphResult[k] = newGraphResult[k].map((d) => {
                                            let categorytemp = [...d["categories"]];
                                            categorytemp = categorytemp.sort();
                                            for (let key in d) {
                                                if (key !== "categories") {
                                                    d[key] = d[key].map((data) => {
                                                        let tempdata = []
                                                        data["data"].forEach((gdata, gindex) => {
                                                            tempdata[gindex] = data["data"][d["categories"].indexOf(categorytemp[gindex])]
                                                        })
                                                        data["data"] = tempdata;
                                                        return data
                                                    })
                                                }
                                            }
                                            d["categories"] = categorytemp;
                                            return d;
                                        })
                                    }
                                }
                            }
                            if (typecall === "reportbutton") {
                                enqueueSnackbar('Pdf Generated..', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                            } else {
                                enqueueSnackbar('Chart data Generated..', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                            }
                            setreportdataresponse({ ...decodedResponse });
                            setlactationcurvemaxy(lactationcurvemaxytemp)
                            setlactationcurveminy(lactationcurveminytemp)
                            setgraphdataresponse(newGraphResult);
                            setloaderstatus(false);
                            if (typecall === "reportbutton") {
                                // decodedResponse.graphselected = selectedGraph;
                                if(customLayout && customLayloutWithChanges) {
                                    selectedGraph = updateGraphData();
                                }
                                decodedResponse.graphselected = customLayout ?  graphimgurlarraydata : selectedGraph;
                                let setting = report_function.func(decodedResponse, graphimgurlarraydata, props.herddata, props.subherddata);
                                sethtmlreport(gethtmlfromreportsetting(setting, decodedResponse, graphimgurlarraydata, null, false).html);
                                setreportsettings(setting)
                                setshowiframe(true);
                                setreportgenerated(true);
                            } 
                            else {
                                setToggleEnable(true)
                                setDisableCustomReport(false)
                            }
                        }).catch((err) => {
                            setloaderstatus(false);
                        })
                    } else {
                        setreportdataresponse({ ...decodedResponse });
                        let setting = report_function.func(decodedResponse, graphimgurlarraydata, props.herddata, props.subherddata);
                        sethtmlreport(gethtmlfromreportsetting(setting, decodedResponse, graphimgurlarraydata, null, false).html);
                        setreportsettings(setting)
                        setshowiframe(true);
                        setreportgenerated(true);
                        setloaderstatus(false);
                    }
                }
            }).catch((err) => {
                setloaderstatus(false);
                if (err.response && err.response.status) {
                    let error_message = "data not found to generate report.";

                    if (report_type === "snapshotgoal-report") {
                        error_message = "Files are not available for last 12 months.";
                    }

                    if (report_type === "culling-report") {
                        error_message = "Culling data not found within last 12 months in the latest file.";
                    }

                    if (err?.response?.status === 404) {
                        setReportError(error_message);
                        setshowReportError(true);
                    }
                    if (err?.response?.status === 500) {
                        setValidError("Error occurred while generating Report, Please contact system support team")
                        enqueueSnackbar('Error occurred while generating Report, Please contact system support team', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.ERROR });
                    }
                }
            })
        } else {
            if (typecall === "reportbutton") {
                let localreportdataresponse = { ...reportdataresponse }
                if(customLayout && customLayloutWithChanges) {
                    selectedGraph = updateGraphData();
                } 
                localreportdataresponse.graphselected = customLayout ?  graphimgurlarraydata : selectedGraph;
                let setting = report_function.func(localreportdataresponse, graphimgurlarraydata, props.herddata, props.subherddata);
                sethtmlreport(gethtmlfromreportsetting(setting, localreportdataresponse, graphimgurlarraydata, null, false).html);
                setreportsettings(setting);
                setshowiframe(true);
                setreportgenerated(true);
                setloaderstatus(false);

                // To help scroll to the pdf preview
                setgeneratereportcounter(generatereportcounter + 1);
            } else {
                setDisplayChart(true);
                setloaderstatus(false);
            }
        }
    }

    const savepdf = (report, type) => {
        let htmldata = gethtmlfromreportsetting(reportsettings, reportResponse, graphimgurlarraydata, reportlayoutsetting, true);
        let html = htmldata.html;
        html = ReactDOMServer.renderToString(html);
        if(type === "pdf")
            enqueueSnackbar('Downloading PDF, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });
        else 
            enqueueSnackbar('Preparing preview page, please wait...', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.DEFAULT });

        var pdf = new jsPDF({
            orientation: 'p',
            unit: 'pt',
            format: 'letter',
            putOnlyUsedFonts: false,
            compress: true,
        });

        pdf.html(html,
            {
                x: 0,
                y: 0,
                width: 612,
                windowWidth: 816,
                callback: function (pdf) {
                    var pageCount = pdf.internal.getNumberOfPages();
                    if (pageCount > htmldata.totalpage) {
                        pdf.deletePage(pageCount);
                    }
                    // check for env, if to add a wm
                    if (getEnvironment() !== ENVIRONMENT.PROD) {
                        pdf = addWatermark(pdf);
                    }
                    let herd = props.herddata;
                    let subherd = props.subherddata;
                    let body = {
                        document: {
                            fileName: report + ' REPORT.pdf',
                            reportType: report,
                            herd: herd?.dairy_name,
                            subHerd: subherd?.description
                        }
                    }
                    sendActionToAdobeAnalytics("download", body)

                    if(type === "pdf") {
                        pdf.save(report + ' REPORT.pdf');
                        enqueueSnackbar('Pdf Downloaded..', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.SUCCESS });
                    } else {
                        const blobURL = URL.createObjectURL(pdf.output('blob'));
                        const newWindow = window.open(blobURL, '_blank');

                        // Check if the new window was blocked by the browser
                        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                            // Show a warning message
                            enqueueSnackbar('Popup blocked. Please allow pop-ups for this site.', { anchorOrigin: { vertical: 'top', horizontal: 'center' }, variant: SNACKBAR_TYPE.WARNING });
                        }
                    }
                    
                }
            });
    }
    
    // ##################################################################
    //  Report Generation Functionality Module and Save PDF END
    // ##################################################################
    const active_subherds = props.herddata.sub_herds.filter(sub => sub.active === true);
    let source_system = props.herddata?.software_herd_software;
    return (
        <Grid container style={{ textAlign: 'left' }} className="report-wrapper">
            {loaderstatus ? <Loader radius={50} type="large" /> :
                <Grid item xs={12} className="report_grid">
                    <h3>Build a Report</h3>
                    <div className="report-section">
                        <h5 className="report-type-heading">Report Type</h5>
                        <div className="psuedo-button-radio-group add-carrot">
                            {
                                reportsetting.map((data, index) => {
                                    let reportstyle = "psudeo-input-item";
                                    let disablebutton = false;

                                    if (reporttab === data.name) {
                                        let reporttext = getreporttext(data.name);
                                        let messagedata = <Alert severity="warning" className="alert-container">This subherd is inactive, data can't be uploaded.</Alert>
                                        
                                        if (props.subherddata.active) {
                                            if (reporttab === "subherdcomparison") {
                                                if (props.herddata.sub_herds.length <= 1 || active_subherds.length === 1) {
                                                    messagedata = <Alert severity="warning" className="alert-container">No sub-herds available for comparison, <a href={"/dashboard/uploadData?herd_id=" + props.herddata.herd_id + "&subherd_id=" + props.subherddata.sub_herd_id}>create</a> sub-herd first.</Alert>
                                                }
                                            } else 
                                                messagedata = <Alert severity="warning" className="alert-container">No test files available. <a href={"/dashboard/uploadData?herd_id=" + props.herddata.herd_id + "&subherd_id=" + props.subherddata.sub_herd_id}>Upload</a> data for this sub-herd first.</Alert>
                                        } 
                                        if (data.name !== "snapshotgoals") {
                                            let dateselection = gettestdates_rep(data);
                                            report_date_selection_section = <React.Fragment>
                                                {
                                                    reporttab !== "subherdcomparison" 
                                                        ? emptyDate ? messagedata : null
                                                    :  props.herddata.sub_herds.length <= 1 || active_subherds.length === 1 ? messagedata : null
                                                }
                                                {
                                                    data.name === "cullinghistory" ?
                                                        <React.Fragment>
                                                            <p>{reporttext}</p>
                                                            {source_system === "DC305" ? <p style={{ whiteSpace: 'pre-line'}}>{dc35cullingreporttext}</p> : null}
                                                        </React.Fragment>
                                                        : <React.Fragment>
                                                            <p style={{ whiteSpace: 'pre-line'}}>{reporttext}</p>
                                                            {data.name !== "periodcomparison" && data.name !== "heiferreport" && source_system === "DC305" ? <p style={{ whiteSpace: 'pre-line'}}>{dc35cullingreporttext}</p> : null}
                                                            <h4 className="section-headline">Date Selection {/* for {data.label} */} </h4>
                                                            <section className="inner-section">
                                                                {dateselection}
                                                            </section>
                                                        </React.Fragment>
                                                }

                                            </React.Fragment>
                                        } else {
                                            text_for_snapshot_goals_report = <React.Fragment>
                                                {emptyDate ? messagedata : null}
                                                <p>{reporttext}</p>
                                                {source_system === "DC305" ? <p style={{ whiteSpace: 'pre-line'}}>{dc35cullingreporttext}</p> : null}
                                            </React.Fragment>
                                        }

                                        reportstyle = "psudeo-input-item active";
                                        reportbutton = <Button variant="contained"
                                            className="button build-report-button"
                                            type="button"
                                            disabled={
                                                reporttab === "subherdcomparison" ? 
                                                    props.herddata.sub_herds.length <= 1 
                                                    ? true : false
                                                : emptyDate || disablebutton
                                            }
                                            onClick={async () => {
                                                if(reporttab !== "snapshotgoals") {
                                                    let temp = [];
                                                    const filteredGraphTemplate = graphTemplate.filter(chart => chart.value === true)
                                                    if(customLayout) 
                                                        setCustomGraphData(temp);
                                                    
                                                    temp = await getchartimagebloburlandgenerateReport(data, displayChart, filteredGraphTemplate);
                                                    setgraphimgurlarraydata(temp);
                                                }
                                                setreportgenerationdata(data);
                                                setcallreportcounter(callreportcounter + 1);
                                            }}
                                        >
                                            BUILD {data.label} REPORT
                                            <i className="ag-icon ag-icon-list-ol"></i>
                                        </Button>
                                        reportlabel = data.label;
                                    }

                                    return (
                                        <div key={index} className={reportstyle}
                                            onClick={() => {
                                                if (["cullinghistory", "snapsix", "periodcomparison", "subherdcomparison", "heifer"].includes(data.name)) {
                                                    const newChartData = graphTemplate.filter(item => item.id !== "lactationcurvechart");
                                                    setgraphdata(newChartData)
                                                } else {
                                                    setgraphdata(graphTemplate)
                                                }
                                                setToggleEnable(false);
                                                setDisplayChart(false);
                                                setshowiframe(false);
                                                setreporttab(data.name);
                                                setreportgenerated(false);
                                                setshowtestday(true);
                                                setchartexpanded(true);
                                                setgraphdataresponse(null);
                                                setreportdataresponse(null);
                                                setValidError(false);
                                                setgraphimgurlarraydata([]);
                                                setselectedcustomreportlayout("");
                                                setcustomreportlayout(false)
                                                if (subherdreportlayoutsetting.length > 0) {
                                                    setreportlayoutsetting(_.cloneDeep(subherdreportlayoutsetting[0].reportlayoutsetting));
                                                } else {
                                                    setreportlayoutsetting(_.cloneDeep(defaultreportlayoutsetting));
                                                }
                                                if (["snapshotgoals", "cullinghistory", "periodcomparison"].includes(data.name)) {
                                                    setselectedtestdates([]);
                                                } else {
                                                    let temp = [];
                                                    temp[0] = reporttesdays[0];
                                                    setselectedtestdates(temp);
                                                    setToggleEnable(true);
                                                }
                                            }}>
                                            {data.label}
                                        </div>
                                    )
                                })
                            }
                        </div>
                        {/* on toggle snapshot graphs display */}
                        {reporttab !== "" && showtestday ? <React.Fragment>
                            <div className="report-dates">
                                {report_date_selection_section}
                                {text_for_snapshot_goals_report}
                                <section className="inner-section">
                                    {
                                        customizationallowedreport.includes(reporttab) ? <><h4 className="section-headline">Report Layout</h4>
                                            </> : null
                                    }
                                    <Grid container>
                                        <Grid item xs={6}>
                                            {
                                                customizationallowedreport.includes(reporttab) ? <div className="build-report-button" style={{ padding: "0 0 10px" }}>
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={customreportlayout}
                                                            onChange={() => {
                                                                if (customreportlayout) {
                                                                    if (subherdreportlayoutsetting.length > 0) {
                                                                        setreportlayoutsetting(_.cloneDeep(subherdreportlayoutsetting[0].reportlayoutsetting));
                                                                    } else {
                                                                        setreportlayoutsetting(_.cloneDeep(defaultreportlayoutsetting));
                                                                    }
                                                                }
                                                                setcustomreportlayout(!customreportlayout);
                                                                setselectedcustomreportlayout("");
                                                            }}
                                                            disabled={reporttab === "periodcomparison" ? disableCustomReport : false}
                                                            name="customreport"
                                                        ></Switch>}
                                                        label="Use a Custom Report Layout"
                                                    />
                                                </div> : null
                                            }
                                        </Grid>
                                        <Grid item xs={6} style={{ marginTop: "2px", textAlign: "end" }}>
                                            {
                                                customizationallowedreport.includes(reporttab) ? <Button
                                                    variant="contained"
                                                    className="button grey size-mini"
                                                    type="button"
                                                    size="small"
                                                    onClick={() => {
                                                        setmanagecustomReportOpen(true)
                                                    }}
                                                >MANAGE CUSTOM LAYOUTS</Button> : null
                                            }
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                customreportlayout ? <div className="build-report-button"><Dropdown
                                                    disableedit={false}
                                                    value={selectedcustomreportlayout}
                                                    handler={(event) => {
                                                        let obj = myfavouritereportlist.find((d) => d.id === event.target.value);
                                                        setreportlayoutsetting(_.cloneDeep(obj.reportlayoutsetting));
                                                        // for chart template
                                                        let chartData = obj.chartlayoutsetting.length > 0 ? _.cloneDeep(obj.chartlayoutsetting) : graphTemplate;
                                                        setGraphTemplate(chartData)
                                                        setselectedcustomreportlayout(obj.id);
                                                    }}
                                                    data={{
                                                        className: "drop-down-root required-style",
                                                        label: "My Favorites",
                                                        size: 4,
                                                        name: "customreportdropdown",
                                                        id: "customreportdropdown",
                                                        options: customreportlayoutoptions
                                                    }} /> </div> : null
                                            }
                                        </Grid>
                                    </Grid>

                                    <Accordion 
                                        expanded={chartexpanded} 
                                        elevation={0}
                                        disableGutters={true}
                                        className="reporting-accordion-charts"
                                        onChange={() => {
                                        if (displayChart)
                                            setchartexpanded(!chartexpanded)
                                        }}>

                                        {
                                            !["snapshotgoals", "cullinghistory", "heiferreport"].includes(reporttab) ?
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="reporting-accordion-charts-summary"
                                                >
                                                    <h4 className="section-headline">Charts & Graphs</h4>
                                                    <FormControlLabel
                                                        control={<Switch
                                                            checked={displayChart}
                                                            onChange={handleChangeChart}
                                                            name="checkedA"
                                                            disabled={!toggleEnable || reporttab === "cullinghistory"}
                                                        ></Switch>}
                                                        label="Include Charts and Graphs"
                                                        className="reporting-accordion-charts-toggle-switch"
                                                    />
                                                   
                                                </AccordionSummary> : 
                                                <>
                                                    {displayChart ? <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"><h4 className="section-headline">Charts & Graphs</h4></AccordionSummary> : null}
                                                </>
                                        }
                                        {
                                            displayChart && reporttab !== "snapshotgoals" ? <>
                                                <AccordionDetails className="reporting-accordion-details">
                                                    <Grid container>
                                                        <Grid item xs={12}>
                                                            <ChartAndGraphs
                                                                dairyname={props.herddata.dairy_name}
                                                                subherddata={props.subherddata}
                                                                graphdataresponse={graphdataresponse}
                                                                lactationcurvemaxy={lactationcurvemaxy}
                                                                lactationcurveminy={lactationcurveminy}
                                                                reporttab={reporttab}
                                                                reportResponse={reportResponse}
                                                                selectedtestdates={selectedtestdates}
                                                                setchartgraph={setchartgraph}
                                                                selectedGraph={graphdata}
                                                                setgraphdata={setgraphdata}
                                                                graphimgurlarray={[...graphimgurlarraydata]}
                                                                setDisplayChart={setDisplayChart}
                                                                graphTemplate={graphTemplate}
                                                                setGraphTemplate={setGraphTemplate}
                                                                customLayout={customLayout}
                                                                setCustomLayoutWithChanges={setCustomLayoutWithChanges}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AccordionDetails>
                                            </> : null
                                        }
                                    </Accordion>
                                </section>
                            </div>
                            <Grid container>
                                <Grid item xs={12}>
                                    {reportbutton}
                                </Grid>
                            </Grid>
                        </React.Fragment> : null
                        }
                    </div>
                    <Grid item xs={12}>
                        {validError ? <p className="invalid-msg error-msg">{validError}</p> : ''}
                    </Grid>
                </Grid>
            }
            {/* Show Downloaded Report */}
            {
                reportgenerated ? <React.Fragment>
                    {
                        !customizationallowedreport.includes(reporttab) ?
                            <>
                                <Grid item xs={4} className="report_downloadsection_left">
                                    <h2 className="headline-report-name">{reportlabel} REPORT</h2>
                                </Grid>
                                <Grid item xs={2}></Grid>
                                <Grid item xs={6} className="report_downloadsection_right endalign">
                                    {savepdfbuttonhtml}
                                </Grid>
                            </> : <>
                                <div className="flex_div">
                                    <div>
                                        <h2 className="headline-report-name">{reportlabel} REPORT</h2>
                                    </div>
                                    {!["owner", "contributor", "admin"].includes(props.herddata.AccessDetails.access) ? 
                                        <div className="item_right">
                                            <div className="">{previewReportButton}</div>
                                            <div className="">{savepdfbuttonhtml}</div>
                                            <div  className="">{savereportlayouthtml}</div>
                                        </div> : <div className="item_right">
                                            <div>{previewReportButton}</div>
                                            <div>{savepdfbuttonhtml}</div>
                                            <div>{savesubherdreportlayouthtml}</div>
                                            <div>{savereportlayouthtml}</div>
                                        </div>
                                    }
                                </div>
                            </>
                    }
                </React.Fragment> : null
            }

            {
                showiframe ? <Grid item xs={12} ref={bottomRef}>
                    <div className="pdfwrapper">
                        {htmlreport}
                    </div>
                </Grid> : null
            }
            <div className="list-bottom"></div>
            <TwoButtonDialog
                content={ReportError}
                open={showReportError}
                handleClose={() => { setshowReportError(false) }}
                btnText1="OK"
                btnText2="Cancel"
                handleActionBtn1={() => {
                    setshowReportError(false);
                }}
                handleActionBtn2={() => {
                    setshowReportError(false);
                }}
            />

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Grid container className={classes.paper}>
                        <Grid item xs={12}>
                            <div style={{ textAlign: "left" }}>
                                <Grid container>
                                    <Grid item xs={9}>
                                        <h3>Save Report Layout</h3>
                            Name your Layout to find it easily in future.
                                    </Grid>
                                    <Grid item xs={3} style={{ textAlign: "right" }}>
                                        <IconButton
                                            aria-label="close"
                                            className="close"
                                            onClick={() => {
                                                handleClose();
                                            }}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Textbox
                                            data={{
                                                className: "text-field-root required-style",
                                                name: "customreportname",
                                                id: "customreportname",
                                                type: "text",
                                                label: "Report Layout Name"
                                            }}
                                            onchangefunc={(event) => {
                                                if (reporterrorindex === 2) {
                                                    setcustomreportnameoverwrite(event.target.value);
                                                    setreporterrorindex(-1);
                                                } else {
                                                    setcustomreportname(event.target.value);
                                                    setreporterrorindex(-1);
                                                }
                                            }}
                                        />
                                    </Grid>

                                    {
                                        reporterrorindex === 2 ? <>
                                            <Grid item xs={12}>
                                                <span style={{ color: "red" }}>
                                                    {reporterrorarray[2]}
                                                </span>
                                            </Grid>

                                            <Grid item xs={6}>
                                                <Button
                                                    variant="contained"
                                                    className="button"
                                                    type="button"
                                                    size="small"
                                                    onClick={() => {
                                                        let name = customreportnameoverwrite === "" ? customreportname : customreportnameoverwrite;
                                                        savefavouritereportlayout(name, myfavouritereportlist.find((d) => d.name === customreportname).id, "overwrite");
                                                    }}
                                                >OVERWRITE EXISTING</Button>
                                            </Grid>
                                            <Grid item xs={6} style={{ textAlign: "end" }}>
                                                <Button
                                                    variant="contained"
                                                    className="button twodialogcancel"
                                                    type="button"
                                                    size="small"
                                                    onClick={() => {
                                                        setreporterrorindex(-1);
                                                    }}
                                                >CANCEL</Button>
                                            </Grid>
                                        </> : <>
                                                {
                                                    reporterrorindex !== -1 ? <Grid item xs={12}>
                                                        <span style={{ color: "red" }}>
                                                            {reporterrorarray[reporterrorindex]}
                                                        </span>
                                                    </Grid> : null
                                                }
                                                <Grid item xs={6}>
                                                    <Button
                                                        variant="contained"
                                                        disabled={customreportname.trim() === ""}
                                                        className="button"
                                                        type="button"
                                                        size="small"
                                                        onClick={() => {
                                                            let flag = false;
                                                            customreportlayoutoptions.forEach((d) => {
                                                                if (d.name === customreportname) {
                                                                    flag = true;
                                                                }
                                                            })

                                                            if (flag) {
                                                                setreporterrorindex(2);
                                                            } else {
                                                                const regex = new RegExp('^[a-zA-Z0-9\\s]+$');
                                                                if (customreportname.trim().length < 3 || !regex.test(customreportname.trim())) {
                                                                    setreporterrorindex(1);
                                                                } else {
                                                                    savefavouritereportlayout(customreportname, null, "new");
                                                                }
                                                            }
                                                        }}
                                                    >SAVE LAYOUT</Button>
                                                </Grid>
                                                <Grid item xs={6} style={{ textAlign: "end" }}>
                                                    <Button
                                                        variant="contained"
                                                        className="button twodialogcancel"
                                                        type="button"
                                                        size="small"
                                                        onClick={() => {
                                                            handleClose();
                                                            setreporterrorindex(-1);
                                                        }}
                                                    >CANCEL</Button>
                                                </Grid>
                                            </>
                                    }
                                </Grid>
                            </div>
                        </Grid>
                    </Grid>
                </Fade>
            </Modal>
            <ModalFlyout width="wide" state={managecustomReportOpen} setstate={() => {
                setmanagecustomReportOpen(!managecustomReportOpen)
                settobeeditedlayoutid(null);
                setreporterrorindex(-1);
            }}>
                <Grid container>
                    <Grid item xs={12}>
                        <h2 style={{ marginBottom: "3%" }}>MANAGE CUSTOM REPORT LAYOUTS</h2>
                        {
                            myfavouritereportlist.map((r, i) => {
                                return <div className="managecustomdiv" key={i}>
                                    <Grid container>

                                        {
                                            tobeeditedlayoutid === r.id ? <>
                                                <Grid item xs={10}>
                                                    <TextField
                                                        label="Report Layout Name"
                                                        name="reportlayoutname"
                                                        type="text"
                                                        variant="outlined"
                                                        className="text-field-root required-style"
                                                        value={customreportname}
                                                        onChange={(event) => {
                                                            setcustomreportname(event.target.value)
                                                            setreporterrorindex(-1);
                                                        }}
                                                    />
                                                </Grid>

                                                {
                                                    myfavouritereportlist[i].name === customreportname.trim() || customreportname.trim().length === 0 ? <>
                                                        <Grid item xs={1} style={{ textAlign: "end" }} >
                                                            <CheckCircleOutlineIcon color="disabled" style={{ fontSize: "2.5rem" }} />
                                                        </Grid>
                                                    </> : <>
                                                            <Grid item xs={1} style={{ textAlign: "end", cursor: "pointer" }} onClick={() => {
                                                                const regex = new RegExp('^[a-zA-Z0-9\\s]+$');
                                                                if (customreportname.trim().length < 3 || !regex.test(customreportname.trim())) {
                                                                    setreporterrorindex(1);
                                                                } else if (myfavouritereportlist.find((d) => d.name === customreportname) !== undefined) {
                                                                    setreporterrorindex(0);
                                                                } else {
                                                                    setshowmyfavoritereportupdateconfirmbox(true);
                                                                }
                                                            }}>
                                                                <CheckCircleOutlineIcon style={{ color: "#00b1ad", fontSize: "2.5rem" }} />
                                                            </Grid>
                                                        </>
                                                }

                                                <Grid item xs={1} style={{ textAlign: "end", cursor: "pointer" }} onClick={() => {
                                                    settobeeditedlayoutid(null);
                                                    setreporterrorindex(-1);
                                                }}>
                                                    <CancelIcon color="disabled" style={{ fontSize: "2.5rem" }} />
                                                </Grid>
                                                {
                                                    tobeeditedlayoutid === r.id && reporterrorindex !== -1 ? <Grid item xs={12}>
                                                        <span style={{ color: "red" }}>{reporterrorarray[reporterrorindex]}</span>
                                                    </Grid> : null
                                                }
                                            </> : <>
                                                    <Grid item xs={8}>{r.name}</Grid>
                                                    <Grid item xs={2} className="layoutedit">
                                                        <div onClick={() => {
                                                            setcustomreportname(r.name);
                                                            settobeeditedlayoutid(r.id);
                                                            setreporterrorindex(-1);
                                                        }}>
                                                            <EditIcon style={{ color: "#00b1ad", fontSize: "1rem" }} />
                                                Edit Name</div>
                                                    </Grid>
                                                    <Grid item xs={2} className="layoutdelete" onClick={() => {
                                                        setselectedmyfavouritereportsetting(r.id);
                                                        setshowmyfavoritedeleteconfirmbox(true);
                                                    }}>
                                                        <DeleteIcon color="disabled" style={{ fontSize: "1rem" }} /> Delete
                                                    </Grid>
                                                </>
                                        }
                                    </Grid>
                                </div>
                            })
                        }

                        {myfavouritereportlist.length === 0 ? <div style={{ color: "#808078" }}>
                            No Report Layout Found.
                        </div> : null}
                    </Grid>
                </Grid>
            </ModalFlyout>

            {/* Report Layout Confirmation Dialogbox */}
            <TwoButtonDialog
                content={"Are you sure you want to make changes to sub-herd report layout?"}
                open={showsavesubherdreportlayoutconfirmbox}
                handleClose={() => { setshowsavesubherdreportlayoutconfirmbox(false) }}
                btnText1="Yes"
                btnText2="No"
                handleActionBtn1={() => {
                    savesubherdreportlayout();
                    setshowsavesubherdreportlayoutconfirmbox(false);
                }}
                handleActionBtn2={() => {
                    setshowsavesubherdreportlayoutconfirmbox(false);
                }}
            />

            <TwoButtonDialog
                content={<>Are you sure you want to rename <span style={{ color: "blue" }}>{myfavouritereportlist.find((d) => d.id === tobeeditedlayoutid)?.name}</span> to {customreportname}?</>}
                open={showmyfavoritereportupdateconfirmbox}
                handleClose={() => { setshowmyfavoritereportupdateconfirmbox(false) }}
                btnText1="Yes"
                btnText2="No"
                handleActionBtn1={() => {
                    savefavouritereportlayout(customreportname, tobeeditedlayoutid, "rename");
                    setshowmyfavoritereportupdateconfirmbox(false);
                }}
                handleActionBtn2={() => {
                    setshowmyfavoritereportupdateconfirmbox(false);
                }}
            />

            <TwoButtonDialog
                content={<>Are you sure you want to delete <span style={{ color: "blue" }}> {myfavouritereportlist.find((d) => d.id === selectedmyfavouritereportsetting)?.name}</span> Report Layout?</>}
                open={showmyfavoritedeleteconfirmbox}
                handleClose={() => { setshowmyfavoritedeleteconfirmbox(false) }}
                btnText1="Yes"
                btnText2="No"
                handleActionBtn1={() => {
                    deletemyfavoritelayout(selectedmyfavouritereportsetting);
                    setshowmyfavoritedeleteconfirmbox(false);
                }}
                handleActionBtn2={() => {
                    setshowmyfavoritedeleteconfirmbox(false);
                }}
            />
        </Grid >

    )
};

export default Reporting;